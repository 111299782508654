import Analytics from './analytics.js';
import {bff} from './analytics.config.js';

export default class BFFAnalytics extends Analytics {
  setProductData(product, index, quantity) {
    return {
      item_name: product.name,
      item_id: product.sku,
      // eslint-disable-next-line no-underscore-dangle
      price: product.__typename === 'configurableProduct' ? product.variants[0].price.final_price : product.price.final_price,
      item_brand: window.analyticsBrand || product.brand,
      item_category: product.product_type,
      item_category_2: null,
      item_category_3: null,
      item_category_4: null,
      // eslint-disable-next-line no-underscore-dangle
      item_variant: product.__typename === 'configurableProduct' ? product.variants[0].name : product.name,
      item_list_name: null,
      item_list_id: null,
      index,
      quantity: quantity || '1',
    };
  }

  trackViewItemListBff(products) {
    const event = {
      event: bff.events.viewItemList,
      ecommerce: {
        items: this.viewItemListDataBff(products),
      },
    };

    this.pushAnalytics(event);
  }

  viewItemListDataBff(products) {
    const productListData = [];
    products.forEach((product, index) => {
      productListData.push(this.setProductData(product, index));
    });
    return productListData;
  }

  trackSelectItemBff(products, productSku) {
    const event = {
      event: bff.events.selectItem,
      ecommerce: {
        items: [this.selectItemDataBff(products, productSku)],
      },
    };

    this.pushAnalytics(event);
  }

  selectItemDataBff(products, productSku) {
    let productData = [];
    products.forEach((product) => {
      if (product.sku === productSku || (product.variants && product.variants[0].sku === productSku)) {
        productData = this.setProductData(product, 1);
      }
    });
    return productData;
  }

  trackViewItemBff(product) {
    const event = {
      event: bff.events.viewItem,
      ecommerce: {
        items: [this.viewItemDataBff(product)],
      },
    };

    this.pushAnalytics(event);
  }

  viewItemDataBff(product) {
    return this.setProductData(product, 1);
  }

  trackAddToCartBff(productData, sku, quantity, pdp) {
    const event = {
      event: bff.events.addToCart,
      ecommerce: {
        items: [this.addToCartDataBff(productData, sku, quantity, pdp)],
      },
    };

    this.pushAnalytics(event);
  }

  addToCartDataBff(productData, sku, quantity, pdp) {
    let addedProduct = [];
    if (pdp) {
      addedProduct = this.setProductData(productData, 1, quantity);
    } else {
      productData.forEach((product) => {
        if (product.sku === sku || (product.variants && product.variants[0].sku === sku)) {
          addedProduct = this.setProductData(product, 1, quantity);
        }
      });
    }
    return addedProduct;
  }
}
