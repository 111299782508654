import { getCookie, setCookieInSeconds } from 'commons/utils';
import { MINIBASKET_CART_ID_SET, ADD_PRODUCT_TO_MINIBASKET_ERROR } from 'commons/constants/events';
import BFFAnalytics from 'commons/libs/analytics/analytics.bff.js';
// ========== BFF =========================

// Used by dynamiclist and dynamicproduct
// need BFF update
export const fetchBffData = async (bffUrl, bffAccessToken, brand, market, graphql, authenticationToken, store) => {
  const myHeaders = new Headers();
  authenticationToken && myHeaders.append('UserSessionToken', `${authenticationToken}`);
  myHeaders.append('Ocp-Apim-Subscription-Key', `${bffAccessToken}`);
  myHeaders.append('Market', `${market}`);
  myHeaders.append('Brand', `${brand}`);
  store && myHeaders.append('Store', `${store}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow',
  };

  return fetch(`${bffUrl}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });
};

export const addProductToCart = async (config, graphql) => {
  const authenticationToken = getCookie(config.authenticationCookie);

  const myHeaders = new Headers();
  authenticationToken && myHeaders.append('UserSessionToken', `${authenticationToken}`);
  myHeaders.append('Ocp-Apim-Subscription-Key', `${config.bffAccessToken}`);
  myHeaders.append('Market', `${config.market}`);
  myHeaders.append('Brand', `${config.brand}`);
  myHeaders.append('Store', `${config.store}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow',
  };

  return fetch(`${config.cartServiceApiUrl}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });
};

export const getCartDetails = async (config, graphql) => {
  const authenticationToken = getCookie(config.authenticationCookie);

  const myHeaders = new Headers();
  authenticationToken && myHeaders.append('UserSessionToken', `${authenticationToken}`);
  myHeaders.append('Ocp-Apim-Subscription-Key', `${config.bffAccessToken}`);
  myHeaders.append('Market', `${config.market}`);
  myHeaders.append('Brand', `${config.brand}`);
  myHeaders.append('Store', `${config.store}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow',
  };

  return fetch(`${config.cartServiceApiUrl}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });
};

export const fetchDataFromM2 = async (config) => {
  const { m2Url, bffAccessToken, graphql, queryType, authenticationCookie } = config;
  const authenticationToken = getCookie(authenticationCookie);
  const headers = new Headers();
  authenticationToken && headers.append('Authorization', `Bearer ${authenticationToken}`);
  bffAccessToken && headers.append('Ocp-Apim-Subscription-Key', `${bffAccessToken}`);
  headers.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers,
    body: graphql,
    redirect: 'follow',
  };

  return fetch(`${m2Url}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`${queryType}() => an error occurred: ${error.message}`);
    });
};

// Used for MOCKS to get JSON from the DAM
export const fetchFromRestAPI = (storeDomain) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const fetchedProducts = fetch(`${storeDomain}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });

  return fetchedProducts;
};

export const fetchMockListings = async (path, listId) => {
  const listingsUrl = `${path}/mocks/listings/${listId}.json`;

  try {
    const listingsResponse = await fetchFromRestAPI(listingsUrl);
    const productsResponse = await Promise.all(
      listingsResponse.list.map(sku => fetchFromRestAPI(`${path}/mocks/products/${sku}.json`)),
    );
    return productsResponse.flatMap(response => response.data.products.items);
  } catch (error) {
    console.error('Error fetching data:', error);
    return '';
  }
}

export const fetchMockData = async (url) => {
  try {
    return await fetchFromRestAPI(url);
  } catch (error) {
    console.error('Error fetching data:', error);
    return '';
  }
}

// E-commerce Mini Basket //////////////////////////////////////
// call "me" query, should return the active cart id, if there is one!
const getCartIdFromMeQuery = async (config) => {
  const authenticationToken = getCookie(config.authenticationCookie);

  const graphql = JSON.stringify({
    // eslint-disable-next-line no-template-curly-in-string
    query: authenticationToken ? config.cartIdFromMeQuery : config.createCart,
  });

  const myHeaders = new Headers();
  authenticationToken && myHeaders.append('UserSessionToken', `${authenticationToken}`);
  myHeaders.append('Ocp-Apim-Subscription-Key', `${config.bffAccessToken}`);
  myHeaders.append('Store', `${config.store}`);
  myHeaders.append('Market', `${config.market}`);
  myHeaders.append('Brand', `${config.brand}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow',
  };

  return fetch(`${config.cartServiceApiUrl}`, requestOptions)
    .then(response => response.json())
    .catch(() => null);
};

export const getCartId = async (config) => {
  let cartId = getCookie(config.cartCookieName);

  if (!cartId) {
    const meMockPath = `${config.mockJsonPath}/mocks/minibasket/me.json`;
    const getCart = config.enableMocks ? await fetchMockData(meMockPath) : await getCartIdFromMeQuery(config);
    const activeCartId = getCart?.data?.me?.active_cart_id || getCart?.data?.createEmptyCart;
    if (activeCartId) {
      cartId = activeCartId;
      setCookieInSeconds(config.cartCookieName, cartId, config.cartExpirationTime, null, config.cartCookiePath);
      window.PubSub.publish(MINIBASKET_CART_ID_SET, { cartId });
    }
  }
  return cartId;
}

const addToCartAnalytics = (productData, sku, quantity, pdp) => {
  this.addToCartAnalytics = new BFFAnalytics().trackAddToCartBff(productData, sku, quantity, pdp);
}

//  Used by PDP and PLP components
export const addProductToMiniBasket = async (config, miniBasketConfig, productData, pdp) => {
  const {
    addToBasketButtonEl,
    sku,
    quantity,
    bundleOptions = [],
    eventType,
    shouldOpenMiniBasket,
    classes,
  } = miniBasketConfig || {};

  const buttonLabelTemp = addToBasketButtonEl.textContent;
  // buyButton(product-card__buy-button) have different child elements in PDP and PLP
  const loaderParent = addToBasketButtonEl.closest(config.selectors.buyButton) || addToBasketButtonEl;
  loaderParent.setAttribute('disabled', '');
  // Set width and height to 'buyButton(product-card__buy-button)' so child element like loading button will have same size
  // Need to be set by JS because layout of the button is dependent on the content
  loaderParent.style.width = `${loaderParent.offsetWidth + 1}px`;
  loaderParent.style.height = `${loaderParent.offsetHeight}px`;

  const loadingEl = document.createElement('span');
  loadingEl.classList.add('batcom--loading');
  loadingEl.textContent = '';
  loadingEl.setAttribute('disabled', '');
  addToBasketButtonEl.innerHTML = '';
  addToBasketButtonEl.appendChild(loadingEl);

  // Fix for the button width in PDP
  if (addToBasketButtonEl.classList.contains('cmp-button')) {
    addToBasketButtonEl.style.width = '100%';
  }

  try {
    const cartId = await getCartId(config);
    // Set cookie when user add product to cart to renew it's expiration
    setCookieInSeconds(config.cartCookieName, cartId, config.cartExpirationTime, null, config.cartCookiePath);

    let cartDetails = null;
    let cartResponseMock = null;
    let cartResponse = null;

    if (config.enableMocks) {
      const addProductToCartMock = `${config.mockJsonPath}/mocks/minibasket/add-product-to-cart.json`;
      const createCartMock = `${config.mockJsonPath}/mocks/minibasket/create-cart.json`;

      if (!cartId) {
        cartResponseMock = await fetchMockData(createCartMock);
      }
      cartResponseMock = await fetchMockData(addProductToCartMock);

      if (cartResponseMock?.data?.addProductsToCart?.cart?.items?.length) {
        const cartDetailsMock = `${config.mockJsonPath}/mocks/minibasket/cart-details.json`;
        cartResponseMock = await fetchMockData(cartDetailsMock);
      } else {
        throw new Error(cartResponseMock?.data?.addProductsToCart?.user_errors[0]?.message);
      }
    }

    const graphqlAddProductToCart = JSON.stringify({
      // eslint-disable-next-line no-template-curly-in-string
      query: config.addProductToCart,
      variables: {
        cartId,
        sku,
        quantity: parseInt(quantity, 10),
        bundleOptions,
      },
    });

    cartResponse = await addProductToCart(config, graphqlAddProductToCart);

    if (cartResponse?.data?.addProductsToCart?.user_errors?.length) {
      throw new Error(cartResponse.data.addProductsToCart.user_errors[0]?.message);
    } else if (cartResponse?.data?.addProductsToCart?.cart?.items?.length) {
      const graphqlGetCartDetails = JSON.stringify({
        // eslint-disable-next-line no-template-curly-in-string
        query: config.getCartDetails?.replace('${cartId}', cartId),
      });

      cartDetails = config.enableMocks ? cartResponseMock : await getCartDetails(config, graphqlGetCartDetails);

      addToBasketButtonEl.textContent = buttonLabelTemp;
      addToBasketButtonEl.classList.add(classes.isSuccess);

      // call get-cart-detail-populated
      window.setTimeout(() => {
        addToBasketButtonEl.classList.remove(classes.isSuccess);
        loaderParent.removeAttribute('disabled');
        window.PubSub.publish(eventType, {
          openBasket: shouldOpenMiniBasket,
          response: cartDetails,
        });
      }, 1000);

      addToCartAnalytics(productData, sku, parseInt(quantity, 10), pdp);
    }
  }
  catch (error) {
    window.PubSub.publish(ADD_PRODUCT_TO_MINIBASKET_ERROR, {
      openBasket: false,
      response: error,
      buyButton: addToBasketButtonEl,
    });
  }
}

// Share the cartID for Shopify
export const hrefCartId = (cartId) => cartId.replace('?', '&');

// Used by E-commerce Mini Basket component and Vuse Canada E-commerce Mini Basket
export const formatCurrency = (currency, amount, locale = document.documentElement.lang) => {
  const amountNumber = Number(amount);

  // Currency need to be configured in page properties in advanced tab (Language) for every page
  // Also it need to be configred in Vuse Canada E-commerce Mini Basket dialog, for E-commerce Mini Basket component this is not needed.
  if (!currency || typeof amountNumber !== 'number') {
    // eslint-disable-next-line no-console
    console.error(`formatCurrency() => Currency: '${currency}' or amount: '${amount}' is not provided!`);
    return '';
  }

  const configuredValue = new Intl.NumberFormat(locale, {style: 'currency', currency})
  return configuredValue.format(amountNumber)
};

// end E-commerce Mini Basket //////////////////////////////////////

// ========== RENDERING =========================

// Product Dynamic List //////////////////////////////////////
export const updateProductLink = (product, productHtml, config) => {
  if (!productHtml || !product.url_key || !config.productPagesUrl) {
    return
  }

  const productLinks = productHtml.querySelectorAll(config.selectors.productDetailPageLink);
  productLinks.forEach(productLink => {
    if (productLink) {
      productLink.href = `${config.productPagesUrl}${product.url_key}`;
    }
  });
}

export const getProductName = (metafields, option ) => {
  for (let i = 0; i < metafields.length; i++) {
    if (metafields[i] && metafields[i].key === option) {
      return metafields[i].value;
    }
  }
  return '';
};

export const getMetafieldValueByKey = (metafields, key) => {
  if (!metafields) {
    return '';
  }

  for (let i = 0; i < metafields.length; i++) {
    if (metafields[i] && metafields[i].key === key) {
      return metafields[i].value;
    }
  }
  return '';
};

export const renderRating = (template, product, pdp) => {
  const ratingsTemplate = pdp ? document.querySelector('#pdp-ratings') : template.querySelector('#plp-ratings');
  const ratingSummary = parseInt(product.rating_summary, 10) > - 1;
  if (pdp && ratingsTemplate && ratingSummary) {
    ratingsTemplate.innerHTML = `
      <div class="product-card__rating batcom-hide">
        <div class="product-card__rating-container">
          <div class="product-card__rating-filled"></div>
        </div>
        <span class="product-card__rating-reviews"></span>
      </div>
    `;
  }
  if (ratingsTemplate && ratingSummary) {
    const ratingContainer = ratingsTemplate.querySelector('.product-card__rating');
    const ratingStarsElement = ratingsTemplate.querySelector('.product-card__rating-filled');
    const ratingReviewsTotal = ratingsTemplate.querySelector('.product-card__rating-reviews');
    const ratingValue = parseInt(product.rating_summary, 10) / 20;
    ratingStarsElement.style.width = `${product.rating_summary}%`;
    ratingContainer.classList.remove('batcom-hide');
    ratingReviewsTotal.textContent = `${ratingValue} (${product.reviews.total_count})`;
  }
};

export const renderPrice = (rawFinalPrice, rawRegularPrice, currency, priceSnippet, savingsLabel, priceLabel) => {
  const regularPrice = parseFloat(rawRegularPrice);
  const finalPrice = parseFloat(rawFinalPrice);
  priceSnippet.textContent = '';
  if (finalPrice && finalPrice < regularPrice) {
    // if the final price is smaller than the regular price, there will be a discount
    const discount = Math.floor(((regularPrice - finalPrice) / regularPrice) * 100);
    const discountHTML = document.createElement('div');
    const priceHTML = document.createElement('div');
    discountHTML.classList.add('product-card__price-discount');
    priceHTML.classList.add('product-card__price-regular');
    discountHTML.innerHTML = `
    <span class="product-card__price-final--price">${formatCurrency(currency, finalPrice)}</span>
    <span class="product-card__price-discount-label">${savingsLabel}</span>
    <span class="product-card__price-discount--precentage">${discount}%</span>`;
    priceHTML.innerHTML = `<span class="product-card__price-regular--price">${priceLabel} ${formatCurrency(currency, regularPrice)}</span>`;
    priceSnippet.appendChild(discountHTML);
    priceSnippet.appendChild(priceHTML);
  } else {
    priceSnippet.innerHTML = formatCurrency(currency, finalPrice);
  }
};

export const renderGloCapsules = (product, template, conf, pdp) => {
  const capsuleWrapper = template.querySelector(`[data-key="capsules"]`);
  const capsules = product.custom_attributes?.find(attr => attr.key === 'capsules')?.value || [];

  if (!capsules || !capsuleWrapper) {
    return;
  }

  capsules.forEach((capsule) => {
    let capsuleElement = '';
    capsuleElement = document.createElement('span');
    capsuleElement.className = "product-card__capsule";
    capsuleElement.dataset.key = `capsule-${capsule}`;
    capsuleElement.innerHTML = `<img src="${conf.capsuleIconsPath}/${capsule}.svg" alt="${capsule}" class="product-card__capsule-icon" data-key="capsule-icon" />`;
    capsuleWrapper.appendChild(capsuleElement);
  });
  capsuleWrapper.classList.remove(conf.classes.hidden);

  if (pdp === 'pdp') {
    const productGallerySwiper = document.querySelector(conf.selectors.swipeMain);
    productGallerySwiper?.classList.add(conf.classes.swiperWithCapsules);
    productGallerySwiper && productGallerySwiper.appendChild(capsuleWrapper);
  }
}

export const renderGloDots = (template, customAttributes, key, conf, pdp) => {
  const dotsNumberElement = pdp ? template.querySelector(`[data-key="${key}-dots-number"]`) : template.querySelector('[data-key="dots-number"]');
  const numberValue = Number(getMetafieldValueByKey(customAttributes, key));
  const levelIndicatorContainers = template.querySelectorAll(conf.selectors.levelIndicatorContainer);

  if (!numberValue || !dotsNumberElement) {
    return;
  }

  levelIndicatorContainers?.forEach((container) => container.classList.remove(conf.classes.hidden));

// strengthIntensityLevelLabel
  if (conf[`${key}Label`] && !pdp) {
    template.querySelector(`[data-key="${key}Label"]`).innerText = conf[`${key}Label`];
  }

  dotsNumberElement.innerText = numberValue;

  const dots = pdp ? template.querySelectorAll(`[data-key="${key}-dot"]`) : template.querySelectorAll(conf.selectors.dot);
  dots.forEach((el, i) => {
    if (i <= (numberValue - 1)) {
      el.classList.add(conf.classes.filled);
    }
  });

  pdp ? template.querySelector(`[data-key="${key}"]`).classList.remove(conf.classes.hidden) : template.querySelector(conf.selectors.dotsWrapper).classList.remove(conf.classes.hidden);
}

export const renderGloCustomAttributes = (product, template, conf, pdp) => {
  renderGloDots(template, product.custom_attributes, 'strengthIntensityLevel', conf, pdp);
  if (pdp) {
    renderGloDots(template, product.custom_attributes, 'strengthCoolingLevel', conf, pdp);
    renderGloDots(template, product.custom_attributes, 'tasteTobaccoLevel', conf, pdp);
    renderGloDots(template, product.custom_attributes, 'tasteFlavourLevel', conf, pdp);
  }

  // render product capsules
  renderGloCapsules(product, template, conf, pdp);
}

export const renderPouches = (template, product, conf) => {
  if (conf.showSubtitle && product.subtitle) {
    const pouchesElement = template.querySelector('[data-key="pouches"]');
    pouchesElement.textContent = product.subtitle;
    pouchesElement.classList.remove(conf.classes.hidden);
  }
}

export const renderBadges = (template, product) => {
  const badges = [
    product.supplementary_block_1,
    product.supplementary_block_2,
  ];

  if (!badges || !badges.length || !template) {
    return;
  }

  const promoElement = template.querySelector('[data-key="promo"]');
  const badgeElement = template.querySelector('[data-key="badge"]');

  if (product.supplementary_block_1 && promoElement) {
    promoElement.textContent = product.supplementary_block_1;
    promoElement.classList.remove('batcom-hide');
  }

  if (product.supplementary_block_2 && badgeElement) {
    badgeElement.textContent = product.supplementary_block_2;
    badgeElement.classList.remove('batcom-hide');
  }
};

// dynamiclist
export const strengthOverlapping = (strengthArray) => strengthArray.filter((value, index, array) => array.indexOf(value) !== index);

// Velo
export const strengthMapping = (strengthLevels, strength) => strengthLevels.filter((mapping) => mapping.label === strength);

// Velo
export const renderStrengthLevels = (config, filters) => {
  const strengthValue = config.strength || config.strengthLevel;
  const strengthMap = strengthMapping(filters ? config.strengthMappings : config.nicotineStrengthMappings, strengthValue);
  const strengthLevels = document.createElement('div');
  if (filters) {
    strengthLevels.classList.add('batcom-dynamiclist-filters__nicotine-levels', 'quick-filter--button');
  } else {
    strengthLevels.classList.add('product-card__nicotine-levels');
  }

  if (!strengthMap.length) {
    // eslint-disable-next-line no-console
    console.error(`renderStrengthLevels() => No strength mapping found for the ${strengthValue} strength!`);
  } else {
    const maximumIndicatorLevel = strengthMap[0].maximumIndicatorLevel;
    const indicatorLevel = strengthMap[0].indicatorLevel;
    // Create span for each indicator
    for (let i = 0; i < maximumIndicatorLevel; i++) {
      const newIndicator = document.createElement('span');
      if (filters) {
        newIndicator.classList.add('batcom-dynamiclist-filters__strength-level', 'quick-filter--button');
      } else {
        newIndicator.classList.add('product-card__strength-level');
      }
      if (i < indicatorLevel) {
        if (filters) {
          newIndicator.classList.add('batcom-dynamiclist-filters__strength-level--filled', 'quick-filter--button');
        } else {
          newIndicator.classList.add('product-card__strength-level--filled');
        }
      }
      strengthLevels.appendChild(newIndicator);
    }
  }

  return strengthLevels;
}

// Velo => update active strength buttons based on active size option (called from renderSizeButtons)
const updateActiveStrengthButtons = (strengthButtons, activeSizeOption, onlyStrengthButton, config) => {
  strengthButtons && strengthButtons.forEach(element => {
    if (element.getAttribute('data-key-size') === activeSizeOption || onlyStrengthButton) {
      element.classList.remove(config.classes.hidden);
    }
  });
}

// Velo
export const renderSizeButtons = (productHTML, product, config) => {
  const sizeOptions = [...new Set(
    product.variants.map(item =>
      item.variant_attributes?.find(attr => attr.code === 'size')?.swatch_data.value,
    ),
  )];

  if (!sizeOptions[0]) {
    return;
  }

  const sizeButtonWrapper = productHTML.querySelector('[data-key="size-buttons"]');
  const strengthButtons = productHTML.querySelectorAll('.product-card__strength-button');

  sizeOptions.forEach((size) => {
    const sizeBtn = document.createElement('button');
    sizeBtn.classList.add('product-card__size-button');
    sizeBtn.textContent = size.toUpperCase();
    sizeBtn.setAttribute('data-key-size', size.toLowerCase());
    sizeBtn.setAttribute('data-key', 'size-button');
    sizeButtonWrapper && sizeButtonWrapper.appendChild(sizeBtn);
  });

  sizeOptions && sizeButtonWrapper.classList.remove(config.classes.hidden);
  const sizeButtons = sizeButtonWrapper.querySelectorAll('[data-key="size-button"]');
  const activeStrengthButton = productHTML.querySelector('.product-card__strength-button.active');
  let activeSizeOption = null;
  let onlyStrengthButton = null;

  if (activeStrengthButton) {
    activeSizeOption = activeStrengthButton.getAttribute('data-key-size');
    sizeButtons.forEach(sizeButton => {
      if (sizeButton.getAttribute('data-key-size').toLowerCase() === activeSizeOption.toLowerCase()) {
        sizeButton.classList.add(config.classes.active);
      }
    });
  }

  if (sizeButtons.length === 1) {
    sizeButtons[0].classList.add(config.classes.active);
    onlyStrengthButton = productHTML.querySelector('.product-card__strength-button');
  }

  updateActiveStrengthButtons(strengthButtons, activeSizeOption, onlyStrengthButton, config);
}

export const setVariantBtnAttributes = (variantBtn, variantConfig, config) => {
  variantBtn.type = 'button';
  variantBtn.setAttribute('data-key-quantity-available', variantConfig.quantityAvailable);
  variantBtn.setAttribute('data-key-product-image', variantConfig.img);
  variantBtn.setAttribute('data-key-product-regular-price', variantConfig.regularPrice);
  variantBtn.setAttribute('data-key-product-final-price', variantConfig.finalPrice);
  variantBtn.setAttribute('data-key-product-currency', variantConfig.currency);
  variantBtn.setAttribute('data-key-option', variantConfig.selectedOption);
  variantBtn.setAttribute('data-key', 'variant-button');
  variantBtn.setAttribute('data-key-sku', variantConfig.sku);
  variantBtn.classList.remove(config.classes.hidden);
}

// Velo

export const renderVeloSizeVariants = (variant, variantConfig, template, config) => {
  const nicotineContainer = template.querySelector('[data-key="nicotine-variations"]');
  const nicotineLabel = nicotineContainer.querySelector('[data-key="nicotine-label"]');
  const nicotineButtons= template.querySelector('[data-key="nicotine-buttons"]');
  const overlappingLevelArray = [];

  if (nicotineLabel) {
    nicotineLabel.textContent = config.nicotineLabel;
  }

  variantConfig.nicotineStrengthMappings = config.nicotineStrengthMappings;
  const size = variant.variant_attributes.find(option => option.code === 'size');
  const strength = variant.variant_attributes.find(option => option.code === 'strength');

  variantConfig.size = size?.swatch_data.value; // Slim
  variantConfig.strength = strength?.swatch_data.value.toUpperCase(); // 6MG
  overlappingLevelArray.push(variantConfig.strength);
  const isOverlapping = overlappingLevelArray.filter((item) => item === variantConfig.strength).length > 1
  if (!isOverlapping) {
    const variantBtn = document.createElement('button');
    variantBtn.classList.add('product-card__strength-button');
    variantConfig.size && variantBtn.setAttribute('data-key-size', variantConfig.size.toLowerCase() || variantConfig.option.toLowerCase());
    variantBtn.setAttribute('data-key-strength', variantConfig.strength || variantConfig.nicotineLevel);

    const strengthLevelLabel = document.createElement('span');
    strengthLevelLabel.classList.add('product-card__strength-level-label');
    strengthLevelLabel.textContent = variantConfig.strength || variantConfig.nicotineLevel;
    variantBtn.appendChild(strengthLevelLabel);

    const strengthLevels = renderStrengthLevels(variantConfig);
    variantBtn.classList.add('batcom-hide');
    variantBtn.appendChild(strengthLevels);

    if (variantConfig.quantityAvailable === 0) {
      variantBtn.classList.add('disabled');
    }
    if (variantConfig.active) {
      variantBtn.classList.add('active');
    }

    setVariantBtnAttributes(variantBtn, variantConfig, config);

    nicotineButtons.appendChild(variantBtn);
    nicotineContainer.classList.remove(config.classes.hidden);
  }
};

// Vuse
export const renderPLPVuseStrengthVariants = (variant, variantConfig, template, config) => {
  const nicotineContainer = template.querySelector('[data-key="nicotine-variations"]');
  const nicotineLabel = nicotineContainer.querySelector('[data-key="nicotine-label"]');
  const nicotineButtons= template.querySelector('[data-key="nicotine-buttons"]');
  if (nicotineLabel) {
    nicotineLabel.textContent = config.nicotineStrengthLabel;
  }
  variantConfig.iconsUrl = config.iconurl;
  const strengthOption = variant.variant_attributes.find(option => option.code === 'strength');
  variantConfig.nicotineLevel = strengthOption.swatch_data.value.toUpperCase();
  const variantBtn = document.createElement('button');
  variantBtn.classList.add('product-card__nicotine-button');
  variantBtn.innerHTML = `<img src="${variantConfig.iconsUrl}${variantConfig.nicotineLevel}.svg" alt="${variantConfig.alt}" class="product-card__nicotine-icon" data-key="nicotine-icon" />`;
  setVariantBtnAttributes(variantBtn, variantConfig, config);
  nicotineButtons.appendChild(variantBtn);
  nicotineContainer.classList.remove(config.classes.hidden);
  if (variantConfig.quantityAvailable === 0) {
    variantBtn.classList.add('disabled');
  }
  if (variantConfig.active) {
    variantBtn.classList.add('active');
  }
};

export const renderVuseStrengthVariants = (variantConfig, template, config) => {
  const strengthContainer = template.querySelector('[data-key="nicotine-variations"]');
  const strengthLabel = template.querySelector('[data-key="nicotine-label"]');
  const strengthButtons = template.querySelector('[data-key="nicotine-buttons"]');
  const variantBtn = document.createElement('button');

  if (variantConfig.available === 0) {
    variantBtn.classList.add('disabled');
  }

  if (variantConfig.active) {
    variantBtn.classList.add('active');
  }

  variantBtn.classList.add('product-card__nicotine-button');
  variantBtn.innerHTML = `<img src="${config.iconurl}${variantConfig.variantSwatchData.toUpperCase()}.svg" alt="${'alt'}" class="product-card__nicotine-icon" data-key="nicotine-icon" />`;

  setVariantBtnAttributes(variantBtn, variantConfig, config);

  strengthLabel.textContent = config.strengthLabel;
  strengthButtons.appendChild(variantBtn);
  strengthContainer.classList.remove(config.classes.hidden);
}

// Glo, Vuse
export const renderPLPColorVariants = (variantConfig, template, config) => {
  const colorContainer = template.querySelector('[data-key="color-variations"]');
  const variantBtn = document.createElement('button');
  // variantBtn.background = mapColorToHex(variantConfig.variantLabel, config.colorVariants); NOT NEEDED

  variantBtn.classList.add('product-card__device-color-btn');
  variantBtn.dataset.key = 'color-button';
  variantBtn.setAttribute('data-key-colorName', variantConfig.variantLabel);
  variantBtn.innerHTML = `<span class="product-card__device-color-btn-inside" style="background-color: ${variantConfig.variantSwatchData}"></span>`;

  if (variantConfig.quantityAvailable === 0) {
    variantBtn.classList.add('disabled');
  }

  // Set active selected color
  if (variantConfig.active) {
    variantBtn.classList.add('active');
    const colorBtnSelected = variantBtn.firstElementChild.cloneNode(true);
    const colorSelectedName = template.querySelector('[data-key="color-selected-name"]');
    template.querySelector('[data-key="color-selected"]')?.appendChild(colorBtnSelected);
    if (colorSelectedName) {
      colorSelectedName.textContent = variantConfig.variantLabel;
    }
  }

  setVariantBtnAttributes(variantBtn, variantConfig, config);

  colorContainer.appendChild(variantBtn);
  colorContainer.classList.remove(config.classes.hidden);
}

export const renderColorVariants = (variantConfig, template, config) => {
  const colorContainer = template.querySelector('[data-key="color-variations"]');
  const colorArea = template.querySelector('[data-key="colors"]');
  const variantBtn = document.createElement('button');
  // variantBtn.background = mapColorToHex(variantConfig.variantLabel, config.colorVariants); NOT NEEDED

  variantBtn.classList.add('product-card__device-color-btn');
  variantBtn.dataset.key = 'color-button';
  variantBtn.setAttribute('data-key-colorName', variantConfig.variantLabel);
  variantBtn.innerHTML = `<span class="product-card__device-color-btn-inside" style="background-color: ${variantConfig.variantSwatchData}"></span>`;

  if (variantConfig.quantityAvailable === 0) {
    variantBtn.classList.add('disabled');
  }

  // Set active selected color
  if (variantConfig.active) {
    variantBtn.classList.add('active');
    const colorBtnSelected = variantBtn.firstElementChild.cloneNode(true);
    template.querySelector('[data-key="color-selected"]').appendChild(colorBtnSelected);
    template.querySelector('[data-key="color-selected-name"]').textContent = variantConfig.variantLabel;
  }

  setVariantBtnAttributes(variantBtn, variantConfig, config);

  template.querySelector('[data-key="color-label"]').textContent = config.colourSelectorLabel;
  template.querySelector('[data-key="color-selected-label"]').textContent = config.colourIndicatorLabel;
  colorArea.classList.remove(config.classes.hidden);
  colorContainer.appendChild(variantBtn);
}

export const variantResolver = (product) => {
  let layout = '';
  const customAttributes = product.custom_attributes || null;
  const variants = product.variants;

  // eslint-disable-next-line no-underscore-dangle
  if (product.__typename === 'BundleProduct') {
    layout = 'bundleProduct';
  } else if (product.brand === 'GLO' && customAttributes && customAttributes.length > 0) {
    // TODO need to come from CAC
    const customKeys = ['strengthIntensityLevel', 'strengthCoolingLevel', 'tasteTobaccoLevel', 'tasteFlavourLevel', 'capsules'];
    const isCustomAttributesLayout = customAttributes.filter(attribute => customKeys.includes(attribute.key));
    if (isCustomAttributesLayout.length > 0) {
      layout = 'gloWithCustomAttributes';
    }
  } else if (variants && variants.length > 0) {
    const variant = variants[0]?.variant_attributes[0]?.code;
    if (variant) {
      layout = variant;
    }
  } else {
    // simple product without variants
    layout = 'simpleProduct';
  }
  return layout;
}
